<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" style="min-height: 80vh;">
        <v-col cols="12" md="4" lg="3" align-self="center">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
              <h3 :style="`color: ${template.colors.inline_primary_text}`">
                SCHIEDSRICHTER REGISTRIERUNG
              </h3>
              <hr />
              <h5 class="white--text">
                SCHRITT {{ step }}:
                <span class="font-weight-light">{{ title[step - 1] }}</span>
              </h5>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              md="auto"
              align-self="center"
              class="py-0"
              v-if="!template.var.logo_only"
            >
              <h2>
                {{ template.var.app_name }}
              </h2>
              <h3 :style="`color: ${template.colors.inline_primary_text}`">
                SCHIEDSRICHTER REGISTRIERUNG
              </h3>
              <hr />
              <h5 class="white--text">
                SCHRITT {{ step }}:
                <span class="font-weight-light">{{ title[step - 1] }}</span>
              </h5>
            </v-col>
            <v-fade-transition hide-on-leave>
              <v-col cols="10" v-if="step == 1">
                <v-form ref="step1" v-model="valid.step1" lazy-validation>
                  <v-text-field
                    v-model="check.id"
                    filled
                    dark
                    label="DFBnet-Kennung"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="check.code"
                    filled
                    dark
                    label="DFBnet-Kennung bestätigen"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-btn
                    block
                    dark
                    large
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    :loading="loader"
                    @click="check_auth()"
                  >
                    WEITER
                  </v-btn>
                </v-form>
              </v-col>
            </v-fade-transition>
            <v-scroll-x-reverse-transition hide-on-leave>
              <v-col cols="10" v-if="step == 3">
                <v-form ref="step4" v-model="valid.step4" lazy-validation>
                  <v-text-field
                    v-model="regdaten.email"
                    filled
                    dark
                    label="E-Mail Adresse"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                  <v-text-field
                    v-model="regdaten.password"
                    filled
                    type="password"
                    dark
                    label="Passwort"
                    :rules="[rules.required, rules.password]"
                  ></v-text-field>
                  <v-text-field
                    v-model="regdaten.passwordconfirm"
                    filled
                    type="password"
                    dark
                    label="Passwort wiederholen"
                    :rules="[rules.required, rules.passwordconfirm]"
                  ></v-text-field>
                </v-form>
                <v-btn
                  block
                  dark
                  large
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  :loading="creating_account"
                  @click="create_account_mail()"
                >
                  WEITER
                </v-btn>
              </v-col>
            </v-scroll-x-reverse-transition>
            <v-scroll-x-reverse-transition hide-on-leave>
              <v-col cols="10" v-if="step == 2">
                <v-form ref="name" v-model="valid.step3" lazy-validation>
                  <v-text-field
                    v-model="regdaten.vorname"
                    filled
                    dark
                    label="Dein Vorname"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                  <v-text-field
                    v-model="regdaten.nachname"
                    filled
                    dark
                    label="Dein Nachname"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-form>
                <v-divider class="my-4"></v-divider>
                <v-btn
                  class="mt-3"
                  block
                  dark
                  large
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  :loading="loader"
                  @click="save_userdaten()"
                >
                  WEITER
                </v-btn>
              </v-col>
            </v-scroll-x-reverse-transition>
            <v-scroll-x-reverse-transition hide-on-leave>
              <v-col cols="10" v-if="step == 4">
                <h2>Registrierung erfolgreich!</h2>
                <span>
                  Du hast erfolgreich einen Schiedsrichter-Account erstellt.
                </span>
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  large
                  class="mt-4"
                  block
                  to="/login"
                >
                  Zum Login
                </v-btn>
              </v-col>
            </v-scroll-x-reverse-transition>
          </v-row>
          <v-row justify="center" class="my-5 pb-5">
            <v-col align-self="center">
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col cols="auto">
              PROBLEME?
            </v-col>
            <v-col align-self="center">
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col cols="10" class="text-center">
              <span>
                Dann hilft dir vielleicht unsere Anleitung weiter
              </span>
            </v-col>
            <v-col cols="10" class="mb-5">
              <v-btn
                block
                dark
                large
                color="white"
                outlined
                :href="
                  template.var.app_url +
                  '' +
                  template.var.anleitungen.schiedsrichter
                "
                target="_blank"
              >
                ANLEITUNG ALS PDF
              </v-btn>
            </v-col>
            <v-col cols="10">
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col cols="10" class="text-center">
              <v-btn text color="white" large to="/datenschutz">
                Datenschutz
              </v-btn>
            </v-col>
            <v-col cols="10" class="text-center">
              <v-btn text color="white" large to="/impressum">Impressum</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :color="template.colors.primary"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-main>
</template>

<script>
//import firebase from '../../firebaseConfig'
import store from '../../store'
import router from '../../routes'
import logout from '../../auth/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      art: 'email',
      check: {
        id: '',
        code: '',
      },
      verein: '',
      show: false,
      valid: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
      },
      form: {
        email: '',
        password: '',
      },
      regdaten: {
        rolle: 'Vorstand',
        verein: {
          logo:
            'https://firebasestorage.googleapis.com/v0/b/flvw-covid-tracker.appspot.com/o/Logos%2Fdefault_logo.png?alt=media&token=7a59fe5c-9b5a-46dc-a85f-3351fd79d31a',
          name: '',
          website: '',
        },
        nachname: '',
        vorname: '',
        alias: '',
        maxzuschauer: '300',
        email: '',
        password: '',
        passwordconfirm: '',
      },
      linkok: '',
      linkexist: '',
      linkloading: false,
      creating_account: false,
      step: 1,
      loader: false,
      error: false,
      errortext: '',
      title: ['AUTORISIERUNG', 'ACCOUNTDATEN', 'ZUGANGSDATEN', 'ERFOLGREICH'],
      logo: {
        dialog: false,
        uploading: false,
        imageData: '',
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        password: (v) =>
          v.length >= 6
            ? true
            : 'Dein Passwort sollte mindestens 6 Zeichen lang sein',
        passwordconfirm: (v) =>
          v == this.regdaten.password
            ? true
            : 'Die Passwörter stimmen nicht überein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {},
  methods: {
    save_userdaten() {
      if (this.$refs.name.validate()) {
        this.step = 3
      }
    },
    closeDialog() {
      this.logo = {
        dialog: false,
        uploading: false,
        imageData: '',
      }
    },
    upload() {
      if (this.logo.imageData) {
        this.regdaten.verein.logo = this.logo.imageData
        this.logo.uploading = false
        this.logo.imageData = ''
        this.logo.dialog = false
      }
    },
    create_account_mail() {
      if (this.$refs.step4.validate()) {
        this.creating_account = true
        var functions = firebase.app().functions('europe-west3')
        var addUser = functions.httpsCallable('addSchiedsrichterAccount')
        addUser({
          user: {
            email: this.regdaten.email,
            password: this.regdaten.password,
            vorname: this.regdaten.vorname,
            nachname: this.regdaten.nachname,
          },
          check: {
            id: this.check.id,
            code: this.check.code,
          },
        })
          .then((result) => {
            if (result.data.success) {
              this.step = 4
              this.creating_account = true
            } else {
              this.error = true
              this.creating_account = false
              if (
                result.data.errortext.errorInfo.code ==
                'auth/email-already-exists'
              ) {
                this.errortext =
                  'Es ist bereits ein User mit dieser E-Mail Adresse vorhanden. Versuche es mit einer anderen E-Mail Adresse noch einmal.'
              } else {
                this.errortext = result.data.errortext.errorInfo.message
              }
            }
          })
          .catch((error) => {
            this.error = true
            console.log(error)
            this.errortext = error
          })
      }
    },
    async check_auth() {
      if (this.$refs.step1.validate()) {
        this.loader = true
        this.error = false
        this.errortext = ''
        if (this.check.id == this.check.code) {
          if (
            !this.check.id.startsWith('21') &&
            !this.check.id.startsWith('23') &&
            !this.check.id.startsWith('66.')
          ) {
            this.loader = false
            this.error = true
            this.errortext =
              'Die eingegebene Kennung existiert nicht oder wird bereits verwendet'
            return
          }

          firebase
            .firestore()
            .collection('Schiedsrichter')
            .doc(this.check.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.loader = false
                this.error = true
                this.errortext =
                  'Die eingegebene Kennung wird bereits verwendet'
              } else {
                this.step = 2
                this.loader = false
              }
            })
        } else {
          this.loader = false
          this.error = true
          this.errortext = 'Die eingegebenen Kennungen stimmen nicht überein'
        }
      }
    },
    submit() {
      this.loader = true
      this.error = null
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .catch((err) => {
          this.loader = false
          this.error = true
          this.errortext = err
        })
    },
  },
}
</script>
>
